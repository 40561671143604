<template>
  <ConteggiDesktop v-if="!$vuetify.breakpoint.mobile" />
  <ConteggiMobile v-else/>
</template>

<script>
import ConteggiDesktop from "@/components/Conteggi/desktop/conteggi_desktop.vue"
import ConteggiMobile from "@/components/Conteggi/mobile/conteggi_mobile_main.vue"
export default {
  components: {
    ConteggiDesktop,
    ConteggiMobile
  },
};
</script>