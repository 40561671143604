<template>
  <v-list-item>
    <v-list-item-content @click="$refs.dettaglio_cont.open()">
      <v-list-item-title>
        {{ cliente.nome }}
      </v-list-item-title>
      <v-list-item-subtitle v-if="ordine">
        Ordine # {{ ordine.numero }} del {{ ordine.data }}
      </v-list-item-subtitle>
      <v-list-item-subtitle>
        Tot Ordine: € {{ tot_ordine.toFixed(2) }}
      </v-list-item-subtitle>
      <v-list-item-subtitle>
        Tot Conto: € {{ tot_conteggio.toFixed(2) }}
      </v-list-item-subtitle>
      <v-list-item-subtitle>
        Discrepanza:
        <v-icon size="16" :color="discrepanza != 0 ? 'amber' : 'green'">{{
          discrepanza != 0 ? "mdi-alert" : "mdi-check"
        }}</v-icon>
        <span :class="discrepanza >= 0 ? 'green--text' : 'red--text'"
          >€ <span v-if="discrepanza > 0">+</span>
          {{ discrepanza.toFixed(2) }}</span
        >
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <v-btn
        icon
        color="green"
        :loading="loading_invia"
        @click="spedisci_conteggio()"
        ><v-icon>mdi-send</v-icon></v-btn
      >
    </v-list-item-action>
    <DettaglioConteggio
      ref="dettaglio_cont"
      :conteggio="conteggio"
      :ordine="ordine"
    />
  </v-list-item>
</template>

<script>
import conteggio_element_mixin from "../mixin/conteggio_element_mixin";
import DettaglioConteggio from "./dettaglio/conteggio_dettaglio_mobile.vue";
export default {
  components: {
    DettaglioConteggio,
  },
  mixins: [conteggio_element_mixin],
};
</script>