<template>
  <v-list>
    <template v-for="conteggio in conteggi">
      <ConteggioEl
        :key="conteggio.id"
        :conteggio="conteggio"
        @invio_ok="show_sheet()"
        @invio_fail="show_sheet_fail()"
      />
      <v-divider :key="conteggio.id + '-div'" />
    </template>
    <v-bottom-sheet v-model="invio_ok">
      <v-sheet class="text-center py-8">
        <v-icon color="green" class="mr-2">mdi-check</v-icon> Inviato
      </v-sheet>
    </v-bottom-sheet>
    <v-bottom-sheet v-model="invio_fail">
      <v-sheet class="text-center py-8">
        <v-icon color="red" class="mr-2">mdi-cancel</v-icon> Errore server locale
      </v-sheet>
    </v-bottom-sheet>
  </v-list>
</template>

<script>
import ConteggioEl from "./conteggio_element_mobile.vue";
export default {
  components: {
    ConteggioEl,
  },
  data() {
    return {
      invio_ok: false,
      invio_fail: false
    };
  },
  computed: {
    conteggi() {
      return this.$store.getters.get_conteggi;
    },
  },
  methods: {
    show_sheet() {
      this.invio_ok = true;
      setTimeout(() => {
        this.invio_ok = false;
      }, 2000);
    },
    show_sheet_fail(){
      this.invio_fail = true;
      setTimeout(() => {
        this.invio_fail = false;
      }, 2000);
    }
  },
};
</script>