<template>
  <v-row>
    <v-col cols="12">
      <ConteggiAttivi/>
    </v-col>
    <v-col cols="12">
      <ConteggiArchivio/>
    </v-col>
  </v-row>
</template>

<script>
import ConteggiAttivi from "./conteggi_attivi.vue"
import ConteggiArchivio from "./conteggi_archivio.vue"
export default {
  components: {
    ConteggiAttivi,
    ConteggiArchivio
  },
};
</script>

