<template>
  <v-row v-if="loading" justify="center">
    <v-progress-circular
      size="20"
      width="2"
      color="green"
      indeterminate
      class="my-4"
    />
  </v-row>
  <v-row align="center" v-else>
    <v-col cols="2">
      {{ cliente.nome }}
    </v-col>
    <v-col cols="1">
      <template v-if="ordine"> # {{ ordine.numero }} </template>
    </v-col>
    <v-col cols="2">{{ data_estesa }}</v-col>
    <v-col cols="2"> {{ tot_ordine.toFixed(2) }} € </v-col>
    <v-col cols="2"> {{ tot_conteggio.toFixed(2) }} € </v-col>
    <v-col cols="1" :class="discrepanza >= 0 ? 'green--text' : 'red--text'"
      ><span v-if="discrepanza > 0">+</span
      >{{ discrepanza.toFixed(2) }} €</v-col
    >
    <v-col cols="2">
      <v-btn icon color="blue" @click="$refs.dettaglio_conteggio.open()">
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
      <DettaglioConteggio
        :ordine="ordine"
        :conteggio="conteggio"
        ref="dettaglio_conteggio"
      />
      <v-dialog v-model="dialog_elimina" width="400">
        <template v-slot:activator="{ on }">
          <v-btn icon color="red" v-on="on">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="red--text"> Elimina Conteggio </v-card-title>
          <v-card-text> Sei Sicuro? </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              outlined
              color="red"
              @click="elimina_conteggio()"
              :loading="loading_elimina"
              >Elimina</v-btn
            >
            <v-btn text color="grey" @click="dialog_elimina = false"
              >annulla</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-btn
        icon
        color="green"
        :loading="loading_invia"
        @click="spedisci_conteggio()"
        ><v-icon>mdi-send</v-icon></v-btn
      >
    </v-col>
  </v-row>
</template>

<script>
// import moment from "moment";
import DettaglioConteggio from "./dettaglio_conteggio/conteggio_dettaglio.vue";
import conteggio_element_mixin from "../mixin/conteggio_element_mixin";
export default {
  components: {
    DettaglioConteggio,
  },
  mixins: [conteggio_element_mixin],
};
</script>