import moment from "moment";
export default {
  props: {
    conteggio: Object,
  },
  data() {
    return {
      dialog_elimina: false,
      ordine: null,
      loading: true,
      loading_elimina: false,
      loading_invia: false,
    };
  },
  mounted() {
    console.log("Mount Conteggi")
    if (!this.conteggio.id_ordine) {
      this.loading = false;
      return;
    }
    this.loading = true;
    let ord = this.$store.getters.get_ordine_by_id(this.conteggio.id_ordine);
    console.log(ord)
    if (!ord) {
      console.log("fetching ordine by id ordine")
      this.$store
        .dispatch("fetch_ordine_by_id", this.conteggio.id_ordine)
        .then((res) => {
          console.log(res)
          this.ordine = res;
          this.loading = false;
        });
    } else {
      this.ordine = ord;
      this.loading = false;
    }
  },
  computed: {
    cliente() {
      return this.$store.getters.get_cliente_by_iva(this.conteggio.cliente);
    },
    data_estesa() {
      return moment.unix(this.conteggio.ts).format("DD/MM/YYYY HH:mm");
    },
    tot_conteggio() {
      // let conteggio = this.$store.getters.get_conteggi.find(
      //   (conteggio) => conteggio.id == this.conteggio.id
      // );
      let conteggio = this.conteggio;
      let listino = null;
      if (this.cliente.listino == 1) {
        listino = 1;
      } else if (this.cliente.listino == 2) {
        listino = 2;
      } else {
        listino = 3;
      }
      return conteggio.prodotti
        .map((prodotto) => {
          if (prodotto.prezzo_contato) {
            return prodotto.qta * prodotto.prezzo_contato;
          }
          let prod = this.$store.getters.get_prodotto_by_id(
            prodotto.id_prodotto
          );
          if (listino == 1) {
            return parseFloat(prod.prezzo1) * prodotto.qta;
          } else if (listino == 2) {
            return parseFloat(prod.prezzo2) * prodotto.qta;
          } else {
            return parseFloat(prod.prezzo3) * prodotto.qta;
          }
        })
        .reduce((sum, tot) => sum + tot);
    },
    tot_ordine() {
      if (!this.conteggio.id_ordine) {
        return 0;
      }
      console.log("ordine:")
      console.log(this.ordine)
      return this.ordine.articoli
        .filter((art) => !art.eliminato)
        .map((prodotto) => {
          return prodotto.colori
            .map((col) => {
              return (col.qta_admin ? col.qta_admin : col.numero) * prodotto.prezzo;
            })
            .reduce((sum, tot) => tot + sum);
        })
        .reduce((sum, tot) => tot + sum);
    },
    discrepanza() {
      return this.tot_conteggio - this.tot_ordine;
    },
  },
  methods: {
    elimina_conteggio() {
      this.loading_elimina = true;
      this.$store
        .dispatch("elimina_conteggio", this.conteggio.id)
        .finally(() => {
          this.loading_elimina = false;
        });
    },
    spedisci_conteggio() {
      this.loading_invia = true;
      this.$store
        .dispatch("invia_csv", this.conteggio.id)
        .then(() => {
          this.$emit("invio_ok");
        })
        .catch(() => {
          this.$emit("invio_fail");
        })
        .finally(() => {
          this.loading_invia = false;
        });
    },
  },
};
