<template>
    <v-tabs color="green">
        <v-tab>Attivi</v-tab>
        <v-tab>Archivio</v-tab>
        <v-tab-item>
            <ConteggiAttivi/>
        </v-tab-item>
        <v-tab-item>
            <ConteggiEvasi/>
        </v-tab-item>
    </v-tabs>
</template>

<script>
import ConteggiAttivi from "./conteggi_attivi_mobile.vue"
import ConteggiEvasi from "./conteggi_evasi_mobile.vue"
export default {
    components:{
        ConteggiAttivi,
        ConteggiEvasi
    }
}
</script>