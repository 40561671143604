<template>
  <v-card :height="height">
    <v-card-title class="grey--text text-subtitle-1">
      Conteggi Archiviati
      <v-btn icon @click="expand()" class="ml-4">
        <v-icon>{{
          is_expanded ? "mdi-chevron-up" : "mdi-chevron-down"
        }}</v-icon>
      </v-btn>
      <v-autocomplete
        v-model="iva_cliente"
        :items="clienti"
        hide-details
        outlined
        dense
        rounded
        label="Cerca..."
        class="ml-4"
        item-text="nome"
        item-value="iva"
        @change="ricerca()"
        clearable
      ></v-autocomplete>
      <v-spacer/>
    </v-card-title>
    

    <v-card-text :class="is_expanded ? 'scroll_extended' : 'scroll'">
      <v-divider class="mb-2" />
      <v-row class="font-weight-medium">
        <v-col cols="2"> Cliente </v-col>
        <v-col cols="1"> Ordine </v-col>
        <v-col cols="2"> Data </v-col>
        <v-col cols="2"> Totale Ordine </v-col>
        <v-col cols="2"> Totale Conto </v-col>
        <v-col cols="2"> Discrepanza </v-col>
      </v-row>
      <v-divider class="mt-2 mb-4" />
      <ConteggioEl
        v-for="conteggio in conteggi"
        :conteggio="conteggio"
        :key="conteggio.id"
      />
      <v-row class="py-8" v-if="!conteggi.length" justify="center">
        Nessuna Conteggio Effettuato
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import ConteggioEl from "./conteggio_element";
export default {
  components: {
    ConteggioEl,
  },
  data() {
    return {
      is_expanded: false,
      height: 400,
      iva_cliente: null,
    };
  },
  computed: {
    conteggi() {
      return this.$store.getters.get_conteggi_evasi;
    },
    clienti() {
      return this.$store.getters.get_clienti;
    },
  },
  methods: {
    expand() {
      if (this.is_expanded) {
        this.is_expanded = false;
        this.height = 400;
      } else {
        this.is_expanded = true;
        this.height = 700;
      }
    },
    ricerca() {
      this.$store.dispatch("fetch_conteggi_by_iva", this.iva_cliente);
    },
  },
};
</script>


<style scoped>
::-webkit-scrollbar {
  width: 2px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #bbbbbb;
}
.scroll {
  height: 310px;
  overflow-y: scroll;
}

.scroll_extended {
  height: 610px;
  overflow-y: scroll;
}
</style>