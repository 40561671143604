<template>
  <v-list>
    <v-autocomplete
      v-model="iva_cliente"
      :items="clienti"
      hide-details
      outlined
      dense
      rounded
      label="Cerca..."
      class="my-4"
      item-text="nome"
      item-value="iva"
      @change="ricerca()"
      clearable
    ></v-autocomplete>
    <template v-for="conteggio in conteggi">
      <ConteggioEl
        :key="conteggio.id"
        :conteggio="conteggio"
        @invio_ok="show_sheet()"
        @invio_fail="show_sheet_fail()"
      />
      <v-divider :key="conteggio.id + '-div'" />
    </template>
    <v-bottom-sheet v-model="invio_ok">
      <v-sheet class="text-center py-8">
        <v-icon color="green" class="mr-2">mdi-check</v-icon> Inviato
      </v-sheet>
    </v-bottom-sheet>
    <v-bottom-sheet v-model="invio_fail">
      <v-sheet class="text-center py-8">
        <v-icon color="red" class="mr-2">mdi-cancel</v-icon> Errore server
        locale
      </v-sheet>
    </v-bottom-sheet>
  </v-list>
</template>

<script>
import ConteggioEl from "./conteggio_element_mobile.vue";
export default {
  components: {
    ConteggioEl,
  },
  data() {
    return {
      invio_ok: false,
      invio_fail: false,
      iva_cliente: null,
    };
  },
  computed: {
    conteggi() {
      return this.$store.getters.get_conteggi_evasi;
    },
    clienti() {
      return this.$store.getters.get_clienti;
    },
  },
  methods: {
    ricerca() {
      this.$store.dispatch("fetch_conteggi_by_iva", this.iva_cliente);
    },
    show_sheet() {
      this.invio_ok = true;
      setTimeout(() => {
        this.invio_ok = false;
      }, 2000);
    },
    show_sheet_fail() {
      this.invio_fail = true;
      setTimeout(() => {
        this.invio_fail = false;
      }, 2000);
    },
  },
};
</script>